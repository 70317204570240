import cn from "clsx";
import { FC, PropsWithChildren } from "react";
import styles from "./Container.module.scss";

type InnerProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

export type ContainerProps = InnerProps &
    PropsWithChildren & {
        /**
         * Determines the width of the container
         * @default "xlarge"
         */
        width?: "full" | "xlarge" | "large" | "medium" | "small" | "xsmall";

        /**
         * Overrides the default styles for spacing at each breakpoint
         * Accepts CSS <length> and <percentage> values
         */
        spacing?: {
            xs?: string;
            sm?: string;
            md?: string;
            lg?: string;
            xl?: string;
        };

        /**
         * Determines horizontal position of the container within its parent
         * @default "center"
         */
        position?: "left" | "right" | "center";

        /**
         * Class name for the container that wraps children
         */
        contentsClassName?: string;
    };

export const defaultContainerSpacing = {
    xs: "var(--g-spacing-lg)",
    sm: "var(--g-spacing-lg)",
    md: "var(--g-spacing-xl)",
    lg: "var(--g-spacing-xl)",
    xl: "var(--g-spacing-md)",
};

export const Container: FC<ContainerProps> = ({
    children,
    className,
    contentsClassName,
    spacing,
    position = "center",
    width = "xlarge",
    ...props
}) => {
    const wrapperClassName = cn(
        styles.wrapper,
        {
            [styles["wrapper--full"]]: width === "full",
            [styles["wrapper--padded"]]: width !== "full",
        },
        className,
    );

    const internalContentsClassName = cn(
        {
            [styles[`contents--${width}`]]: width,
            [styles[`contents--${position}`]]: position,
        },
        contentsClassName,
    );

    return (
        <div
            {...props}
            className={wrapperClassName}
            style={
                {
                    "--container-spacing-xs":
                        spacing?.xs ?? defaultContainerSpacing.xs,
                    "--container-spacing-sm":
                        spacing?.sm ?? defaultContainerSpacing.sm,
                    "--container-spacing-md":
                        spacing?.md ?? defaultContainerSpacing.md,
                    "--container-spacing-lg":
                        spacing?.lg ?? defaultContainerSpacing.lg,
                    "--container-spacing-xl":
                        spacing?.xl ?? defaultContainerSpacing.xl,
                    ...props.style,
                } as React.CSSProperties
            }
        >
            <div className={internalContentsClassName}>{children}</div>
        </div>
    );
};
