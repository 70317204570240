import { useAuth } from "@wojo/auth";
import LogRocket from "logrocket";
import { useEffect } from "react";
import { WhitelistPath } from "./types";

/**
 * @summary Initializes LogRocket with the given whitelist paths. If the user is logged in, it will identify the user.
 * @note This hook must be within the scope of the AuthContextProvider.
 */
export const useLogRocketIdentify = (whitelistPaths: WhitelistPath[]) => {
    const { customer, isLoggedIn } = useAuth();
    useEffect(() => {
        const logRocketAppId = process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID;
        const isEnabled =
            process.env.NEXT_PUBLIC_IS_LOG_ROCKET_ENABLED === "true";
        const pathname = window.location.pathname;
        const isInWhitelistPath = whitelistPaths.some((whitelistPath) => {
            const isForBrand =
                !whitelistPath.businessCodes ||
                (process.env.NEXT_PUBLIC_BUSINESS_CODE &&
                    whitelistPath.businessCodes.includes(
                        process.env.NEXT_PUBLIC_BUSINESS_CODE,
                    ));
            const isWhitelistPath = whitelistPath.includeNestedPages
                ? pathname.startsWith(whitelistPath.path)
                : pathname === whitelistPath.path;
            return isForBrand && isWhitelistPath;
        });
        if (logRocketAppId && isEnabled && isInWhitelistPath) {
            if (isLoggedIn && customer?.customerId) {
                LogRocket.identify(customer.customerId, {
                    name: `${customer.firstName} ${customer.lastName}`,
                    email: customer.email ?? "",
                });
            }
        }
    }, [customer, isLoggedIn, whitelistPaths]);
};
