import LogRocket from "logrocket";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";
import { WhitelistPath } from "./types";

/**
 * @description initialize logrocket before apollo to attach logrocket to `fetch` and `xhr` requests
 * https://docs.logrocket.com/docs/troubleshooting-sessions#other-network-libraries
 */
export const initLogRocket = (whitelistPaths: WhitelistPath[]) => {
    if (typeof window !== "undefined") {
        const logRocketAppId = process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID;
        const isEnabled =
            process.env.NEXT_PUBLIC_IS_LOG_ROCKET_ENABLED === "true";
        const pathname = window.location.pathname;
        const isInWhitelistPath = whitelistPaths.some((whitelistPath) => {
            const isForBrand =
                !whitelistPath.businessCodes ||
                (process.env.NEXT_PUBLIC_BUSINESS_CODE &&
                    whitelistPath.businessCodes.includes(
                        process.env.NEXT_PUBLIC_BUSINESS_CODE,
                    ));
            const isWhitelistPath = whitelistPath.includeNestedPages
                ? pathname.startsWith(whitelistPath.path)
                : pathname === whitelistPath.path;
            return isForBrand && isWhitelistPath;
        });
        if (logRocketAppId && isEnabled && isInWhitelistPath) {
            const { requestSanitizer, responseSanitizer } =
                LogrocketFuzzySanitizer.setup([
                    "password",
                    "email",
                    "identityDocumentNumber",
                    "issueDate",
                    "expirationDate",
                    "accountNumber",
                ]);
            LogRocket.init(logRocketAppId, {
                release: process.env.VERCEL_GIT_COMMIT_SHA,
                network: {
                    // @ts-ignore bad vendor types
                    requestSanitizer,
                    // @ts-ignore bad vendor types
                    responseSanitizer,
                },
            });
        }
    }
};
